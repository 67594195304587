<template>
  <v-app>
    <v-carousel
      :show-arrows="false"
      cycle
      interval=3000
      height="300"
    >
      <v-carousel-item
        v-for="(item,i) in items"
        :key="i"
        :src="item.src"
      ></v-carousel-item>
    </v-carousel>
    <v-container style="margin-top:12px">
      <v-layout>
        <div class="text-h5">活动</div>
      </v-layout>
      <v-layout
        v-for="(item,i) in card1List"
        :key="i"
        style="padding-top:6px"
      >
        <v-card
          style="height: 140px;padding:12px"
          flat
          @click="gotoClassDetail(item)"
        >
          <v-layout
            row
            style="width:100%;height:100%;"
          >
            <div style="width:40%">
              <v-img
                :src="item.src"
                style="height:100%"
              >
              </v-img>
            </div>
            <v-layout
              column
              style="padding-left:12px;width:60%"
            >
              <v-layout style="font-size:20px;height:22px">{{item.title}}
              </v-layout>
              <v-layout style="font-size:14px;height:14px;color:gray">{{item.subTitle}}
              </v-layout>
              <v-layout style="height:40px">
              </v-layout>
              <v-layout
                row
                align-baseline
                style="padding-left:12px"
              >
                <v-layout
                  justify-start
                  style="width:50%"
                >
                  <label style="color:red;font-size:20px">
                    {{item.price}}</label>
                </v-layout>
                <v-layout
                  justify-end
                  style="width:50%;te"
                >
                  <label>
                    {{item.memberNum}}</label>
                </v-layout>
              </v-layout>
            </v-layout>
          </v-layout>
        </v-card>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>

export default {
  name: 'Home',

  data () {
    return {
      items: [
        {
          src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg'
        },
        {
          src: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg'
        },
        {
          src: 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg'
        },
        {
          src: 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg'
        }
      ],
      card1List: [
        {
          src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
          title: '活动1',
          subTitle: '活动内容',
          price: '￥100',
          memberNum: '100人参与',
          type: 1,
          sumary: 'You are the doorway to god。 我们所专注的是如何帮助您把课程里的每一点内容实践到你的生活里。如何可以更好地生活。',
          detail: '\u3000\u3000如果你在想，你以后可以成为一个好的妈妈、好的孩子、好的伴侣……一个有出息的人时，你的真实是你只是在想这些。' +
            '\n\u3000\u3000而在你与别人互动的过程中，无论你想成为谁，无论你将来想做得多好，这一刻，你如果在对他们不耐烦、冷漠、忽视，' +
            '那么，这个不耐烦的你、冷漠的你、忽视的你，才是你当下生命的真实展现．\n\u3000\u3000如果你说，你早已经了解了真实的生活是什么，' +
            '你也完全地热爱着生活，那么，这里有个小测试：\n\u3000\u3000想象一下，如果你现在停止所有的灵修活动，停止看所有的灵修书籍，' +
            '切断所有灵性信息的来源，你是否可以安住于生活里，并且觉得一切都很美好？\n\u3000\u3000如果可以，那么，在你所在的地方就好，' +
            '因为你没有别的地方可以去。\n\u3000\u3000如果你不能，那么这个课程，可能会是一个契机。我不会因为想要让你开心，或者喜欢我，' +
            '而说什么，我只会说你需要看到的事实。而你需要自己去看，你不用相信我，不用喜欢我，不用尊敬我，这个老师的身份是个虚假的身份。' +
            '我不会真的认为我是你的老师，因为你从来也不是在跟我说话，你的老师只是你自己。\n\u3000\u3000当你无法知晓内在的真相时，' +
            '你会投射出一个外在的老师，来跟你对话。其实我就是你，而这一点，你迟早都会意识到。现在，停止灵性逃避，回归到真实的生活里来。' +
            '这个生活是场梦没错，但是它却是真相的另一个展示面。\n\u3000\u3000这个生活是你的一切，它同时也是一个入口。活在当下，' +
            '如何算是活在当下？当你在床上想象你的生活是如何美好时，你在不在当下？你当然只能在当下想象。当你在床上懊悔你过去失去了什么，' +
            '做错了什么时，你在不在当下？你当然只能把过去带入当下来体验。\n\u3000\u3000以我自己的经验来讲，活在当下，并不是一个固定的状态。' +
            '随着你的认知的扩展，你对当下的定义会不断地发生变化，而你会随着这些越来越明白“活在当下”到底是什么意思。'
        },
        {
          src: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',
          title: '活动2',
          subTitle: '活动内容',
          price: '￥200',
          memberNum: '200人参与',
          type: 1,
          sumary: 'You are the doorway to god。 我们所专注的是如何帮助您把课程里的每一点内容实践到你的生活里。如何可以更好地生活。',
          detail: '\u3000\u3000如果你在想，你以后可以成为一个好的妈妈、好的孩子、好的伴侣……一个有出息的人时，你的真实是你只是在想这些。' +
            '\n\u3000\u3000而在你与别人互动的过程中，无论你想成为谁，无论你将来想做得多好，这一刻，你如果在对他们不耐烦、冷漠、忽视，' +
            '那么，这个不耐烦的你、冷漠的你、忽视的你，才是你当下生命的真实展现．\n\u3000\u3000如果你说，你早已经了解了真实的生活是什么，' +
            '你也完全地热爱着生活，那么，这里有个小测试：\n\u3000\u3000想象一下，如果你现在停止所有的灵修活动，停止看所有的灵修书籍，' +
            '切断所有灵性信息的来源，你是否可以安住于生活里，并且觉得一切都很美好？\n\u3000\u3000如果可以，那么，在你所在的地方就好，' +
            '因为你没有别的地方可以去。\n\u3000\u3000如果你不能，那么这个课程，可能会是一个契机。我不会因为想要让你开心，或者喜欢我，' +
            '而说什么，我只会说你需要看到的事实。而你需要自己去看，你不用相信我，不用喜欢我，不用尊敬我，这个老师的身份是个虚假的身份。' +
            '我不会真的认为我是你的老师，因为你从来也不是在跟我说话，你的老师只是你自己。\n\u3000\u3000当你无法知晓内在的真相时，' +
            '你会投射出一个外在的老师，来跟你对话。其实我就是你，而这一点，你迟早都会意识到。现在，停止灵性逃避，回归到真实的生活里来。' +
            '这个生活是场梦没错，但是它却是真相的另一个展示面。\n\u3000\u3000这个生活是你的一切，它同时也是一个入口。活在当下，' +
            '如何算是活在当下？当你在床上想象你的生活是如何美好时，你在不在当下？你当然只能在当下想象。当你在床上懊悔你过去失去了什么，' +
            '做错了什么时，你在不在当下？你当然只能把过去带入当下来体验。\n\u3000\u3000以我自己的经验来讲，活在当下，并不是一个固定的状态。' +
            '随着你的认知的扩展，你对当下的定义会不断地发生变化，而你会随着这些越来越明白“活在当下”到底是什么意思。'
        },
        {
          src: 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',
          title: '活动3',
          subTitle: '活动内容',
          price: '￥300',
          memberNum: '300人参与',
          type: 1,
          sumary: 'You are the doorway to god。 我们所专注的是如何帮助您把课程里的每一点内容实践到你的生活里。如何可以更好地生活。',
          detail: '\u3000\u3000如果你在想，你以后可以成为一个好的妈妈、好的孩子、好的伴侣……一个有出息的人时，你的真实是你只是在想这些。' +
            '\n\u3000\u3000而在你与别人互动的过程中，无论你想成为谁，无论你将来想做得多好，这一刻，你如果在对他们不耐烦、冷漠、忽视，' +
            '那么，这个不耐烦的你、冷漠的你、忽视的你，才是你当下生命的真实展现．\n\u3000\u3000如果你说，你早已经了解了真实的生活是什么，' +
            '你也完全地热爱着生活，那么，这里有个小测试：\n\u3000\u3000想象一下，如果你现在停止所有的灵修活动，停止看所有的灵修书籍，' +
            '切断所有灵性信息的来源，你是否可以安住于生活里，并且觉得一切都很美好？\n\u3000\u3000如果可以，那么，在你所在的地方就好，' +
            '因为你没有别的地方可以去。\n\u3000\u3000如果你不能，那么这个课程，可能会是一个契机。我不会因为想要让你开心，或者喜欢我，' +
            '而说什么，我只会说你需要看到的事实。而你需要自己去看，你不用相信我，不用喜欢我，不用尊敬我，这个老师的身份是个虚假的身份。' +
            '我不会真的认为我是你的老师，因为你从来也不是在跟我说话，你的老师只是你自己。\n\u3000\u3000当你无法知晓内在的真相时，' +
            '你会投射出一个外在的老师，来跟你对话。其实我就是你，而这一点，你迟早都会意识到。现在，停止灵性逃避，回归到真实的生活里来。' +
            '这个生活是场梦没错，但是它却是真相的另一个展示面。\n\u3000\u3000这个生活是你的一切，它同时也是一个入口。活在当下，' +
            '如何算是活在当下？当你在床上想象你的生活是如何美好时，你在不在当下？你当然只能在当下想象。当你在床上懊悔你过去失去了什么，' +
            '做错了什么时，你在不在当下？你当然只能把过去带入当下来体验。\n\u3000\u3000以我自己的经验来讲，活在当下，并不是一个固定的状态。' +
            '随着你的认知的扩展，你对当下的定义会不断地发生变化，而你会随着这些越来越明白“活在当下”到底是什么意思。'
        },
        {
          src: 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg',
          title: '活动4',
          subTitle: '活动内容',
          price: '￥400',
          memberNum: '400人参与',
          type: 1,
          sumary: 'You are the doorway to god。 我们所专注的是如何帮助您把课程里的每一点内容实践到你的生活里。如何可以更好地生活。',
          detail: '\u3000\u3000如果你在想，你以后可以成为一个好的妈妈、好的孩子、好的伴侣……一个有出息的人时，你的真实是你只是在想这些。' +
            '\n\u3000\u3000而在你与别人互动的过程中，无论你想成为谁，无论你将来想做得多好，这一刻，你如果在对他们不耐烦、冷漠、忽视，' +
            '那么，这个不耐烦的你、冷漠的你、忽视的你，才是你当下生命的真实展现．\n\u3000\u3000如果你说，你早已经了解了真实的生活是什么，' +
            '你也完全地热爱着生活，那么，这里有个小测试：\n\u3000\u3000想象一下，如果你现在停止所有的灵修活动，停止看所有的灵修书籍，' +
            '切断所有灵性信息的来源，你是否可以安住于生活里，并且觉得一切都很美好？\n\u3000\u3000如果可以，那么，在你所在的地方就好，' +
            '因为你没有别的地方可以去。\n\u3000\u3000如果你不能，那么这个课程，可能会是一个契机。我不会因为想要让你开心，或者喜欢我，' +
            '而说什么，我只会说你需要看到的事实。而你需要自己去看，你不用相信我，不用喜欢我，不用尊敬我，这个老师的身份是个虚假的身份。' +
            '我不会真的认为我是你的老师，因为你从来也不是在跟我说话，你的老师只是你自己。\n\u3000\u3000当你无法知晓内在的真相时，' +
            '你会投射出一个外在的老师，来跟你对话。其实我就是你，而这一点，你迟早都会意识到。现在，停止灵性逃避，回归到真实的生活里来。' +
            '这个生活是场梦没错，但是它却是真相的另一个展示面。\n\u3000\u3000这个生活是你的一切，它同时也是一个入口。活在当下，' +
            '如何算是活在当下？当你在床上想象你的生活是如何美好时，你在不在当下？你当然只能在当下想象。当你在床上懊悔你过去失去了什么，' +
            '做错了什么时，你在不在当下？你当然只能把过去带入当下来体验。\n\u3000\u3000以我自己的经验来讲，活在当下，并不是一个固定的状态。' +
            '随着你的认知的扩展，你对当下的定义会不断地发生变化，而你会随着这些越来越明白“活在当下”到底是什么意思。'
        },
        {
          src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
          title: '活动5',
          subTitle: '活动内容',
          price: '￥500',
          memberNum: '500人参与',
          type: 1,
          sumary: 'You are the doorway to god。 我们所专注的是如何帮助您把课程里的每一点内容实践到你的生活里。如何可以更好地生活。',
          detail: '\u3000\u3000如果你在想，你以后可以成为一个好的妈妈、好的孩子、好的伴侣……一个有出息的人时，你的真实是你只是在想这些。' +
            '\n\u3000\u3000而在你与别人互动的过程中，无论你想成为谁，无论你将来想做得多好，这一刻，你如果在对他们不耐烦、冷漠、忽视，' +
            '那么，这个不耐烦的你、冷漠的你、忽视的你，才是你当下生命的真实展现．\n\u3000\u3000如果你说，你早已经了解了真实的生活是什么，' +
            '你也完全地热爱着生活，那么，这里有个小测试：\n\u3000\u3000想象一下，如果你现在停止所有的灵修活动，停止看所有的灵修书籍，' +
            '切断所有灵性信息的来源，你是否可以安住于生活里，并且觉得一切都很美好？\n\u3000\u3000如果可以，那么，在你所在的地方就好，' +
            '因为你没有别的地方可以去。\n\u3000\u3000如果你不能，那么这个课程，可能会是一个契机。我不会因为想要让你开心，或者喜欢我，' +
            '而说什么，我只会说你需要看到的事实。而你需要自己去看，你不用相信我，不用喜欢我，不用尊敬我，这个老师的身份是个虚假的身份。' +
            '我不会真的认为我是你的老师，因为你从来也不是在跟我说话，你的老师只是你自己。\n\u3000\u3000当你无法知晓内在的真相时，' +
            '你会投射出一个外在的老师，来跟你对话。其实我就是你，而这一点，你迟早都会意识到。现在，停止灵性逃避，回归到真实的生活里来。' +
            '这个生活是场梦没错，但是它却是真相的另一个展示面。\n\u3000\u3000这个生活是你的一切，它同时也是一个入口。活在当下，' +
            '如何算是活在当下？当你在床上想象你的生活是如何美好时，你在不在当下？你当然只能在当下想象。当你在床上懊悔你过去失去了什么，' +
            '做错了什么时，你在不在当下？你当然只能把过去带入当下来体验。\n\u3000\u3000以我自己的经验来讲，活在当下，并不是一个固定的状态。' +
            '随着你的认知的扩展，你对当下的定义会不断地发生变化，而你会随着这些越来越明白“活在当下”到底是什么意思。'
        }
      ]
    }
  },
  methods: {
    gotoClassDetail (data) {
      this.$router.push({
        name: 'classDetail',
        params: {
          img: data.src,
          price: data.price,
          title: data.title,
          sumary: data.sumary,
          detail: data.detail,
          date: '2021.11.08 12:00 - 2022.05.08 00:00'
        }
      })
    }
  }
}
</script>
