<template>
  <v-app>
    <v-container style="margin-top:12px">
      <v-layout>
        <v-img :src="img"></v-img>
      </v-layout>
      <v-card flat>
        <v-layout column>
          <v-layout>
            <label class="lbl-price">{{price}}</label>
          </v-layout>
          <v-layout>
            <label class="lbl-title">{{title}}</label>
          </v-layout>
          <v-layout>
            <label class="lbl-sumary">{{sumary}}</label>
          </v-layout>
          <v-layout>
            <label class="lbl-date">{{'开课时间：' + date}}</label>
          </v-layout>
        </v-layout>
      </v-card>

    </v-container>
    <v-container style="height:12px;background-color:#F5F5F5;padding:0px">
    </v-container>
    <v-container style="margin-bottom:54px">
      <v-card flat>
        <v-layout>
          <label class="lbl-detail-common">{{detail}}</label>
        </v-layout>
      </v-card>
    </v-container>
    <v-layout
      class="nav-bottom"
      align-center
      justify-center
    >
      <v-btn
        rounded
        color="success"
        dark
      >报名
      </v-btn>
    </v-layout>
  </v-app>
</template>

<script>

export default {
  name: 'Home',

  data () {
    return {
      img: this.$route.params.img,
      price: this.$route.params.price,
      title: this.$route.params.title,
      sumary: this.$route.params.sumary,
      detail: this.$route.params.detail,
      date: this.$route.params.date
    }
  }
}
</script>

<style scoped>
.lbl-price {
  color: red;
  font-size: 20px;
  padding-top: 18px;
  padding-bottom: 12px;
}
.lbl-title {
  font-size: 22px;
  font-weight: 700;
  padding-bottom: 6px;
}
.lbl-sumary {
  font-size: 18px;
  color: gray;
  padding-bottom: 12px;
}
.lbl-date {
  font-size: 16px;
  color: #29b6f6;
  padding-bottom: 6px;
}
.lbl-detail-common {
  font-size: 18px;
  white-space: pre-wrap;
}
.nav-bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 54px;
  background-color: white;
}
.nav-bottom .v-btn {
  height: 40px;
  width: 80%;
}
</style>
